<template>
  <div class="params-card">
    <div class="title-data">
      <span>通用字段</span>
      <span class="subTitle">
        注：暂仅支持应用于报名表单中，区分中英文直播间使用，英文直播间将按照英文值显示
      </span>
    </div>
    <div class="params-main">
      <div class="params-card-list">
        <div class="list-table-panel" v-if="tableList.length != 0">
          <!-- 表格与分页 -->
          <vh-table
            ref="tableList"
            :data="tableList || []"
            :header-cell-style="{
              color: '#666',
              height: '56px'
            }"
          >
            <vh-table-column
              align="left"
              v-for="(item, index) in baseTableColumn"
              :key="index"
              :width="item.width"
              :label="item.label"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <span :class="item.key" v-if="item.key == 'field_type'">
                  {{ FieldTypes[scope.row[item.key]] }}
                </span>
                <span :class="item.key" v-else>
                  {{ scope.row[item.key] }}
                </span>
              </template>
            </vh-table-column>
            <vh-table-column label="操作" align="left" width="200px">
              <template slot-scope="scope">
                <span class="tool" @click="editChannel(scope.row)">编辑</span>
              </template>
            </vh-table-column>
          </vh-table>
        </div>
        <!-- 无消息内容 -->
        <null-page class="search-no-data" v-else></null-page>
      </div>
    </div>

    <VhallDialog
      title="编辑"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      custom-class="params-dialog"
      width="800px"
      @close="closeOptionDialog"
    >
      <div class="title">
        <div class="label">中文</div>
        <div class="label">英文</div>
      </div>
      <div class="title">
        <div class="input" :class="{ errorStatus: editParams.error_zh }">
          <vh-input
            v-model="editParams.name_zh"
            :maxlength="100"
            show-word-limit
            placeholder="请输入中文内容"
            autocomplete="off"
          ></vh-input>
          <div class="errorTxt">{{ editParams.error_zh }}</div>
        </div>
        <div class="input" :class="{ errorStatus: editParams.error_en }">
          <vh-input
            v-model="editParams.name_en"
            :maxlength="100"
            show-word-limit
            placeholder="请输入英文内容"
            autocomplete="off"
          ></vh-input>
          <div class="errorTxt">{{ editParams.error_en }}</div>
        </div>
      </div>

      <div class="options">
        <draggable
          class="list-group"
          tag="div"
          handle=".moveBtn"
          v-bind="dragOptions"
          v-model="options"
          @change="sortChange"
          @start="drag = true"
          @end="drag = false"
          :move="onMove"
        >
          <transition-group type="transition" :name="!drag ? 'flip-list' : null">
            <div class="option" v-for="(item, index) of options" :key="item.option_key">
              <div class="label">选项{{ index + 1 }}</div>
              <div class="input" :class="{ errorStatus: item.error_zh }">
                <vh-input
                  v-model="item.name_zh"
                  :maxlength="100"
                  show-word-limit
                  placeholder="请输入中文内容"
                  autocomplete="off"
                ></vh-input>
                <div class="errorTxt">{{ item.error_zh }}</div>
              </div>
              <div class="input" :class="{ errorStatus: item.error_en }">
                <vh-input
                  v-model="item.name_en"
                  :maxlength="100"
                  show-word-limit
                  placeholder="请输入英文内容"
                  autocomplete="off"
                ></vh-input>
                <div class="errorTxt">{{ item.error_en }}</div>
              </div>
              <div class="tools">
                <span class="tool iconfont-v3 saasicon_move moveBtn"></span>
                <span
                  class="tool iconfont-v3 saasicon-trash"
                  @click="deleteOption(index, item)"
                ></span>
              </div>
            </div>
          </transition-group>
        </draggable>
        <vh-button type="primary" size="mini" @click="addOption" round>添加</vh-button>
      </div>
      <span slot="footer" class="dialog-footer create-footer">
        <vh-button type="info" size="medium" @click="closeOptionDialog" plain round>取消</vh-button>
        <vh-button type="primary" size="medium" v-preventReClick @click="saveChannelHandle" round>
          确定
        </vh-button>
      </span>
    </VhallDialog>
  </div>
</template>
<script>
  import regRule from '@/utils/reg-rule.js';
  import draggable from 'vuedraggable';
  import NullPage from '../PlatformModule/Error/nullPage.vue';
  const FieldTypes = ['默认', '问答', '单选', '多选', '下拉', '地域', '隐私协议'];
  export default {
    data() {
      return {
        FieldTypes,
        tableList: [],
        baseTableColumn: [
          /*   {
            label: 'ID',
            key: 'id',
            width: 'auto'
          }, */
          {
            label: '名称',
            key: 'field_name',
            width: 'auto'
          },
          {
            label: '类型',
            key: 'field_type',
            width: 'auto'
          }
        ],
        total: 0,
        dialogVisible: false,
        activeName: 'zh',
        options: [],
        editParams: {
          option_key: '',
          name_zh: '',
          name_en: '',
          error_zh: false,
          error_en: false
        },
        drag: false
      };
    },
    components: {
      NullPage,
      draggable
    },
    computed: {
      dragOptions() {
        return {
          animation: 200,
          group: 'description',
          disabled: false,
          ghostClass: 'ghost'
        };
      }
    },
    created() {},
    mounted() {
      this.getChannelTableList();
    },
    methods: {
      deleteOption(index, info) {
        this.options.splice(index, 1);
      },
      addOption() {
        if (this.options.length >= 50) {
          this.$vhMessage({
            message: `最多仅支持添加50个选项`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return;
        }
        this.options.push({
          option_key: 'option' + new Date().getTime(),
          name_zh: '',
          name_en: '',
          error_zh: false,
          error_en: false
        });
        this.$nextTick(() => {
          document
            .querySelector('.list-group')
            .scrollTo(0, document.querySelector('.list-group').scrollHeight);
        });
      },
      onMove({ relatedContext, draggedContext }) {
        // const relatedElement = relatedContext.element;
        // const draggedElement = draggedContext.element;
        // console.log(relatedContext, draggedContext);
        return true;
      },
      sortChange(val, arr) {},
      handleClick(tab, event) {
        console.log(tab, event);
      },
      getChannelTableList() {
        let params = {
          lang_type: 0
        };
        this.$fetch('getFieldList', this.$params(params), {
          'Content-Type': 'application/x-www-form-urlencoded'
        }).then(res => {
          this.tableList = res.data.list || [];
        });
      },
      editChannel(row) {
        let params = {
          field_key: row.field_key
        };
        this.$fetch('getFieldInfo', this.$params(params), {
          'Content-Type': 'application/x-www-form-urlencoded'
        }).then(res => {
          const data = res.data;
          this.editParams.option_key = data.field_key;
          data.field_names.map(e => {
            if (e.lang_type == '0') {
              this.editParams.name_zh = e.field_name;
            }
            if (e.lang_type == '1') {
              this.editParams.name_en = e.field_name;
            }
          });
          this.editParams.error_zh = false;
          this.editParams.error_en = false;
          this.options = [];
          data.field_options.map(e => {
            let name_zh = '';
            let name_en = '';
            e.option_names.map(e2 => {
              if (e2.lang_type == '0') {
                name_zh = e2.option_name;
              }
              if (e2.lang_type == '1') {
                name_en = e2.option_name;
              }
            });
            this.options.push({
              option_key: e.option_key,
              name_zh: name_zh,
              name_en: name_en,
              error_zh: false,
              error_en: false
            });
          });

          this.dialogVisible = true;
          this.$nextTick(() => {
            document.querySelector('.list-group').scrollTo(0, 0);
          });
        });
      },
      saveChannelHandle() {
        for (let index = 0; index < this.options.length; index++) {
          let element = this.options[index];
          if (!element.name_zh) {
            this.options[index].error_zh = '请输入内容';
          } else if (!regRule.xssJava.test(element.name_zh)) {
            this.options[index].error_zh = '请输入正确的内容';
          } else {
            this.options[index].error_zh = false;
          }

          if (!element.name_en) {
            this.options[index].error_en = false;
          } else if (!regRule.xssJava.test(element.name_en)) {
            this.options[index].error_en = '请输入正确的内容';
          } else {
            this.options[index].error_en = false;
          }
        }
        if (!this.editParams.name_zh) {
          this.editParams.error_zh = '请输入中文内容';
        } else if (!regRule.xssJava.test(this.editParams.name_zh)) {
          this.editParams.error_zh = '请输入正确的内容';
        } else {
          this.editParams.error_zh = '';
        }

        if (!this.editParams.name_en) {
          this.editParams.error_en = '请输入英文内容';
        } else if (!regRule.xssJava.test(this.editParams.name_en)) {
          this.editParams.error_en = '请输入正确的内容';
        } else {
          this.editParams.error_en = '';
        }

        if (this.editParams.error_zh || this.editParams.error_en) {
          return;
        }
        const er = this.options.every(e => {
          return !e.error_zh && !e.error_en;
        });

        if (er) {
          if (this.options.length < 1) {
            this.$vhMessage({
              message: `请至少设置一个选项`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
            return;
          }

          this.$vhConfirm('将同步修改已被应用到表单的值，可能会影响数据统计，是否保存？', '提示', {
            confirmButtonText: '保存',
            cancelButtonText: '取消',
            customClass: 'zdy-message-box',
            lockScroll: false,
            roundButton: true,

            cancelButtonClass: 'zdy-confirm-cancel'
          }).then(() => {
            let field_names = [];
            let field_options = [];
            field_names.push(
              {
                lang_type: 0,
                field_name: this.editParams.name_zh && this.editParams.name_zh.trim()
              },
              {
                lang_type: 1,
                field_name: this.editParams.name_en && this.editParams.name_en.trim()
              }
            );
            this.options.map(e => {
              field_options.push({
                option_key: e.option_key.startsWith('option') ? '' : e.option_key,
                option_names: [
                  {
                    lang_type: 0,
                    option_name: e.name_zh && e.name_zh.trim()
                  },
                  {
                    lang_type: 1,
                    option_name: e.name_en && e.name_en.trim()
                  }
                ]
              });
            });

            this.$fetch(
              'saveFieldInfo',
              this.$params({
                field_key: this.editParams.option_key,
                field_type: 2,
                field_names: JSON.stringify(field_names),
                field_options: JSON.stringify(field_options)
              }),
              {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            )
              .then(res => {
                if (res && res.code === 200) {
                  this.dialogVisible = false;
                  this.getChannelTableList();
                } else {
                  this.$vhMessage({
                    message: res.msg || `保存失败`,
                    showClose: true,
                    // duration: 0,
                    type: 'error',
                    customClass: 'zdy-info-box'
                  });
                }
              })
              .catch(res => {
                this.$vhMessage({
                  message: res.msg || `保存失败`,
                  showClose: true,
                  // duration: 0,
                  type: 'error',
                  customClass: 'zdy-info-box'
                });
              });
          });
        }
      },
      closeOptionDialog() {
        this.dialogVisible = false;
        this.options = [];
      }
    }
  };
</script>
<style lang="less" scoped>
  .params-card-list {
    background-color: #fff;
    padding: 24px;
    min-height: 676px;
  }
  .params-card {
    .title-data {
      margin: 0 0 20px 0;
      text-align: left;
      line-height: 30px;
      span {
        font-size: 22px;
        font-weight: 600;
        color: #1a1a1a;
      }
      .subTitle {
        font-size: 14px;
        font-weight: 400;
        color: #666;
        margin-left: 12px;
      }
      .tool {
        color: #3562fa;
        font-weight: 400;
        font-size: 14px;
        cursor: pointer;
      }
      .button-tip {
        vertical-align: top;
      }
    }
    .params-main {
      .vh-card__body {
        padding: 32px 24px;
      }
      .search-data {
        margin-bottom: 30px;
      }

      .search-tag {
        float: right;
        width: 220px;
      }

      .list-table-panel {
        min-height: auto;
        .vh-table {
          .vh-table__body .cell {
            .webinar_num,
            .tool {
              color: #3562fa;
              cursor: pointer;
            }
          }
          .vh-table__row .tool {
            margin-right: 16px;
          }
          td:last-child,
          th:last-child {
            padding-right: 24px;
            text-align: right;
          }
          .tip {
            margin-left: 6px;
          }

          .eps {
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
    .params-dialog {
      .title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        .input {
          width: 358px;
          height: 50px;
          line-height: 50px;

          .errorTxt {
            display: none;
          }
        }
        .label {
          width: 356px;
          text-align: left;
          font-size: 16px;
        }
      }
      .indent {
        padding-left: 80px;
        line-height: 1.5;
      }

      .options {
        .list-group {
          max-height: 400px;
          overflow-y: auto;
          margin-bottom: 10px;
          min-height: 90px;
        }

        .option {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          .label {
            width: 60px;
          }
          .vh-input {
            width: 298px;
          }
          > .input:nth-last-child(2) {
            margin-left: 20px;
          }
          .tools {
            .tool {
              cursor: pointer;
              font-size: 22px;
              margin-left: 5px;
            }
          }
          .input {
            height: 50px;
            line-height: 50px;
            .errorTxt {
              display: none;
            }
          }
        }
      }
    }
  }
  .no-live {
    .btn-list .vh-button {
      margin-right: 0;
    }
  }
</style>
<style lang="less">
  .params-card {
    .vh-input--limit .vh-input__inner {
      padding-right: 58px;
    }
    .params-dialog .options .option .input,
    .params-dialog .title .input {
      &.errorStatus {
        .vh-input__inner {
          border-color: #fb2626;
        }
        .errorTxt {
          display: block;
          font-size: 80%;
          margin-top: -20px;
          height: 0;
          color: #fb2626;
        }
      }
    }
  }
  .vh-tooltip__popper {
    max-width: 450px;
  }
</style>
